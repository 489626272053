



























import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PersonService from "@/services/PersonService";
import IdCardDto from "@/dto/person/IdCardDto";
import PersonalIdCardModal from "@/components/profile/person/PersonalIdCardModal.vue";
import UserDTO from "@/dto/UserDTO";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class PersonalIdCard extends Vue {
  private successful = false;
  private message = "";

  private idCardDto!: IdCardDto;

  private isHaveIdCard = false;

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @AppModule.State
  private isMobile!: boolean;

  @Auth.State("user")
  private user!: UserDTO;

  mounted() {
    this.loadIdCard()
  }

  loadIdCard() {
    this.startLoading();
    this.message = "";
    PersonService.getPersonalIdCard(Workspaces.getCurrent.id).then(
        response => {
          this.idCardDto = response.data;
          this.isHaveIdCard = !!this.idCardDto.idCardFront && !!this.idCardDto.idCardBack
          this.successful = true;
          this.stopLoading()
        },
        error => this.errorHandle(error)
    )
  }

  showModal() {
    this.$modal.show(
        PersonalIdCardModal,
        {
          onSaved: () => this.loadIdCard()
        },
        {width: "50%", height: "auto", scrollable: true}
    );
  }

  errorHandle(error: any) {
    this.successful = false;
    this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
    this.stopLoading();
  }

}
